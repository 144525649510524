import { createI18n } from "vue-i18n"
import messages from "@intlify/unplugin-vue-i18n/messages"
const i18n = createI18n({
  messages,
  locale: (localStorage || window.localStorage).getItem("locale") || "ar",
  globalInjection: true,
  legacy: false
})

export default i18n
