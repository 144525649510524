import logoCentered from "@/layout/logoCentered.vue"

const informationRoutes = [
  {
    path: "/contact-us",
    name: "contact",
    meta: {
      layout: logoCentered,
      authGard: false
    },

    component: () => import("@/views/contact-us.vue")
  },
  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    meta: {
      layout: logoCentered,
      authGard: false
    },
    component: () => import("@/views/terms-and-conditions.vue")
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    meta: {
      layout: logoCentered,
      authGard: false
    },
    component: () => import("@/views/privacy-policy.vue")
  }
]

export default informationRoutes
