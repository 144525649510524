<template>
  <div class="ag-back">
    <slot class="action-btn" name="icon"></slot>
    <slot class="action-btn" name="content"></slot>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
