import boardLayout from "@/layout/boardLayout.vue"
const accountRoutes = [
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/profile/profile.vue")
  },
  {
    path: "/profile/edit",
    name: "editProfile",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/profile/editProfile.vue")
  },

  {
    path: "/account-settings",
    name: "accountSettings",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/settings/accountSettings.vue")
  },

  {
    path: "/settings",
    name: "settings",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/settings/settings.vue")
  },
  {
    path: "/settings/edit",
    name: "editSettings",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/settings/editSettings.vue")
  },
  {
    path: "/view/profile/as-vistor/:member_id",
    name: "profilePreview",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/user-info/profilePreview.vue")
  },
  {
    path: "/personal-logs",
    name: "personalLogs",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/personalLogs.vue")
  },
  {
    path: "/:member_id/profile-customize",
    name: "profileCustomize",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/user-info/profileCustomize.vue")
  },
  {
    path: "/profile/user-info",
    name: "userInfo",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/user-info/userInfo")
  },
  {
    path: "/profile/edit-info",
    name: "EditInfo",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/user/user-info/editInfo.vue")
  }
]
export default accountRoutes
