import stadioLayout from "@/layout/stadioLayout.vue"

const stadioRoutes = [
  {
    path: "/agenda/stadio",
    name: "stadio",
    meta: {
      layout: stadioLayout,
      authGard: false
    },

    component: () => import("@/views/stadio/form-builder.vue")
  }
]
export default stadioRoutes
