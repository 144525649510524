import boardLayout from "@/layout/boardLayout.vue"

const settingsRoutes = [
  {
    path: "/entities/:entity_id/settings",
    name: "entitySettings",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/settings")
  },
  // {
  //   path: "/entities/:entity_id/rotations",
  //   name: "entityRotations",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },

  //   component: () => import("@/views/entity/list/rotations"),
  // },
  {
    path: "/entities/:entity_id/recycle",
    name: "entity-recycle",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/entity/settings/recycle")
  },

  {
    path: "/entities/:entity_id/logs",
    name: "entityLogs",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/entityLogs")
  },

  {
    path: "/entities/:entity_id/customize",
    name: "entityCustomize",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/customize")
  },
  {
    path: "/entities/:entity_id/entity_data",
    name: "entityData",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/entityData")
  },
  {
    path: "/entities/:entity_id/entity_data/edit",
    name: "editEntityData",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/settings/editEntityData")
  },
  {
    path: "/entities/:entity_id/subscriptionView",
    name: "subscriptionView",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () =>
      import("@/views/entity/subscriptions/subscreption_view.vue")
  }
]

export default settingsRoutes
