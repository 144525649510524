import { ai } from "@/plugins/axios"

import { defineStore } from "pinia"
export const useEntityStore = defineStore({
  id: "Entity",
  state: () => ({
    entityInfo: []
  }),
  actions: {
    async updateEntityInfo(entityId) {
      try {
        const { data } = await ai.get(
          `method/agenda.api.entity.entity.get_entity_information`,
          { params: { entity_id: entityId } }
        )
        this.entityInfo = data
      } catch (err) {
        console.log(err)
      }
    }
  }
})
