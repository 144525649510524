import { ai } from "@/plugins/axios"
import { useNotificationStore } from "@/store/toaster"

async function getImage(imgSrc) {
  if (imgSrc?.includes("http")) return imgSrc
  try {
    let response = await ai.get(`https://dev.agenda.42.guru${imgSrc}`, {
      responseType: "arraybuffer"
    })
    // response.data is an ArrayBuffer
    // convert ArrayBuffer to Base64 string
    let Base64string = btoa(
      String.fromCharCode.apply(null, new Uint8Array(response.data))
    )
    let imgSrcBase64 = `data: image/jpg; base64, ${Base64string}`
    return imgSrcBase64
  } catch (e) {
    console.log(e)
  }
}
// Function to show notifications using the Notification Store
function agToaster(type, message, description, icon) {
  const notificationStore = useNotificationStore()
  notificationStore.addNotification({
    type: type,
    message: message,
    description: description,
    icon: {
      name: icon.name,
      bg: icon.bg
    }
  })
}
function parseJwt(token) {
  console.log(token)
  var base64Url = token.split(".")[1]
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}
export { getImage, agToaster, parseJwt }
