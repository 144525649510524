<template>
  <div @click="clickToClosemenu" :class="underAppsOpen ? 'underAppsOpen' : ''">
    <space-hero v-if="space_id" @click="clickToClosemenu" />

    <main-hero v-else @click="clickToClosemenu" />

    <div @click="outAppclicked()" class="min-h-screen">
      <Suspense>
        <template #default>
          <router-view
            @refreshHero="rerenderHero"
            @showUnderApps="handelDisapperApps"
            v-slot="{ Component }"
          >
            <!-- <transition name="translate" mode="out-in"> -->
            <transition name="fade-transition">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue"

import { ai } from "@/plugins/axios"

export default {
  name: "DefaultLayout",

  computed: {
    managementApps() {},
    member_id() {
      const user = JSON.parse(localStorage.getItem("user"))
      return user?.member_id
    },
    closeNow() {
      return this.closeApps
    }
  },
  data() {
    return {
      scrolledHeight: 0,
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      memberId: "",
      board: false,
      closeApps: false,
      entity: false,
      home: true,
      spaceUpdated: false,
      entityUpdated: false,
      userUpdated: false,
      editSpace: false,
      editEntity: false,
      editUser: false,
      logoPic: "",
      coverPic: "",
      fixedApps: "",
      form: {
        logo: null,
        cover_image: null
      },
      mt: false,
      underAppsOpen: false
    }
  },

  methods: {
    rerenderHero() {
      this.rerender = false
      // set
      const instance = getCurrentInstance()

      instance?.proxy?.$forceUpdate()
    },

    clickToClosemenu(event) {
      const dropdown = document.querySelector(".app-dropdown-list")
      const dropdownlist = document.querySelector(".list")
      if (dropdown) {
        if (dropdown.contains(event.target)) {
          this.outAppclicked()
        } else {
        }
      }
    },
    handelDisapperApps(e) {
      this.underAppsOpen = e
    },
    outAppclicked() {
      this.closeApps = !this.closeApps
    },
    async loadSpaceInfo() {
      try {
        const { data } = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: this.space_id } }
        )
        this.form.logo = data.logo
        this.form.cover_image = data.cover_image

        this.logoPic = `https://dev.agenda.42.guru/` + data.logo
        this.coverPic = `https://dev.agenda.42.guru/` + data.cover_image
      } catch (err) {}
    },

    async loadUserInfo(memberId) {
      try {
        this.loading = true
        const { data } = await ai.get(
          `method/agenda.api.user.user.get_member_information`,
          { params: { member_id: memberId } }
        )

        this.form.logo = data.member_information.user_image
        this.form.cover_image = data.member_information.banner_image

        this.logoPic =
          `https://dev.agenda.42.guru/` + data.member_information.user_image
        this.coverPic =
          `https://dev.agenda.42.guru/` + data.member_information.banner_image
      } catch (error) {}
    },

    setImageAvatar(formData) {
      this.form.logo = formData.get("image")
      this.submitFormAvatar()
    },
    setImageCover(formData) {
      this.form.cover_image = formData.get("image")
      this.submitFormCover()
      //
    },
    async submitFormAvatar() {
      if (this.editSpace) {
        this.isLoading = true
        try {
          const spaceInfo = new FormData()
          spaceInfo.append("logo", this.form.logo)
          spaceInfo.append("space_id", this.space_id)
          const res = await ai.post(
            "method/agenda.api.space.space.update_space",
            spaceInfo
          )

          this.isLoading = false
          this.spaceUpdated = true
          setTimeout(() => {
            this.spaceUpdated = false
          }, 2000)
        } catch (err) {
          this.isLoading = false
        }
      } else if (this.editEntity) {
        try {
          const entityInfo = new FormData()
          entityInfo.append("company_logo", this.form.logo)
          entityInfo.append("entity_id", this.entity_id)
          const { data } = await ai.post(
            "method/agenda.api.entity.entity.update_entity",
            entityInfo
          )

          localStorage.setItem("entity_info", JSON.stringify(data))
          this.$router.push(`/${this.entity_id}/entity_data`)

          this.isLoading = false
          this.entityUpdated = true
          setTimeout(() => {
            this.entityUpdated = false
          }, 2000)
        } catch (error) {
          this.isLoading = false
        }
      } else if (this.editUser) {
        try {
          const userInfo = new FormData()
          userInfo.append("user_image", this.form.logo)
          userInfo.append("member_id", this.memberId)
          await ai.post(
            "method/agenda.api.user.user.update_user_info",
            userInfo
          )

          this.isLoading = false
          this.userUpdated = true
          setTimeout(() => {
            this.userUpdated = false
          }, 1000)
        } catch (error) {
          console.log(error)
          this.isLoading = false
        }
      }
    },
    async submitFormCover() {
      if (this.editSpace) {
        this.isLoading = true
        try {
          const spaceInfo = new FormData()
          spaceInfo.append("cover_image", this.form.cover_image)
          spaceInfo.append("space_id", this.space_id)
          const res = await ai.post(
            "method/agenda.api.space.space.update_space",
            spaceInfo
          )

          this.isLoading = false
          this.spaceUpdated = true
          setTimeout(() => {
            this.spaceUpdated = false
          }, 2000)
        } catch (err) {
          console.log(err)
          this.isLoading = false
        }
      } else if (this.editEntity) {
        try {
          const entityInfo = new FormData()
          entityInfo.append("company_cover", this.form.cover_image)
          entityInfo.append("entity_id", this.entity_id)
          await ai.post(
            "method/agenda.api.entity.entity.update_entity",
            entityInfo
          )

          this.isLoading = false
          this.entityUpdated = true
          setTimeout(() => {
            this.entityUpdated = false
          }, 2000)
        } catch (error) {
          console.log(error)
        }
      } else if (this.editUser) {
        try {
          const userInfo = new FormData()
          userInfo.append("banner_image", this.form.cover_image)
          userInfo.append("member_id", this.memberId)
          await ai.post(
            "method/agenda.api.user.user.update_user_info",
            userInfo
          )

          this.isLoading = false
          this.userUpdated = true
          setTimeout(() => {
            this.userUpdated = false
          }, 1000)
        } catch (error) {
          console.log(error)
          this.isLoading = false
        }
      }
    }
  },

  watch: {
    $route(to, from) {
      this.entity_id = this.$route.params.entity_id
      this.space_id = this.$route.params.space_id
    }
  },

  mounted() {
    this.memberId = JSON.parse(localStorage.getItem("user"))?.member_id
  },
  beforeDestroy() {}
}
</script>

<style lang="scss">
:root {
  --scrolled-height: 100px;
}

.mt-95px {
  margin-top: 95px;
  transition: all 0.3s linear;
  // background-color: red;
  margin-top: calc(95px - var(--scrolled-height));

  .header-holder {
    margin-top: 176px;
    margin-top: max(92px, calc(176px - var(--scrolled-height)));
  }
}
</style>
