<template>
  <li class="w-full px-2 py-1.5 first:pt-2 ag-menu-item">
    <router-link class="flex items-center justify-start w-full gap-3" :to="to">
      <div>
        <slot name="icon"></slot>
      </div>
      <div class="flex-shrink-0 text-center text-white">
        <slot name="title"></slot>
      </div>
      <div class="text-white ms-auto">
        <slot name="arrow"></slot>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: "",
      required: true
    }
  },
  data() {
    // Change here: use data as a function
    return {
      entity_id: this.$route.params.entity_id
    }
  }
}
</script>

<style lang="scss">
.ag-menu-item {
  font-size: 14px;
  transition: all 0.2s linear;
  // padding: 12px;
  // border-radius: 8px;
  // opacity: 0.88;
  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #eff1f907;
  }
}
</style>
