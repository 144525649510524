import boardLayout from "@/layout/boardLayout.vue"
import meetingLayout from "@/layout/meetingLayout.vue"

const meetingRoute = [
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings",
  //   name: "meetings",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/meetings.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/add",
  //   name: "add_meeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/meetingSetup.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/suggest-meeting",
  //   name: "suggest-meeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/meetingSuggest.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/suggested-meetings/:suggested_meeting_id",
  //   name: "suggested-meeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/suggestedMeeting.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/complete-suggested-meeting/:suggested_meeting_id",
  //   name: "complete-suggested-meeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () =>
  //     import("@/views/space/meetings/completeSuggestedMeeting.vue"),
  // },
  // // meeting flow
  // // meetingLayout
  // // ------------------
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/start-meeting/:meeting_id",
  //   name: "before_start",
  //   meta: {
  //     layout: meetingLayout,
  //   },
  //   component: () =>
  //     import("@/views/space/meetings/current-meeting-items/before_start.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/start-meeting/current-meeting/:meeting_id",
  //   name: "started_meeting",
  //   meta: {
  //     layout: meetingLayout,
  //   },
  //   component: () =>
  //     import(
  //       "@/views/space/meetings/current-meeting-items/started_meeting.vue"
  //     ),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/meeting_summary/:meeting_id",
  //   name: "meeting_summary",
  //   meta: {
  //     layout: meetingLayout,
  //   },
  //   component: () =>
  //     import(
  //       "@/views/space/meetings/current-meeting-items/meeting_summary.vue"
  //     ),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/meeting-logs/:meeting_id",
  //   name: "meetingLogs",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () =>
  //     import("@/views/space/meetings/current-meeting-items/meetingLogs.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/ended-meeting/:meeting_id",
  //   name: "endedMeeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/endedMeeting.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/:meeting_id/edit",
  //   name: "editMeeting",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/space/meetings/editMeeting.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/meeting-send-to-members/:meeting_id",
  //   name: "meetingSendToMembers",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () =>
  //     import(
  //       "@/views/space/meetings/current-meeting-items/meetingSendToMembers.vue"
  //     ),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/admin/confirm-attendance/:id",
  //   name: "adminconfirmAttendance",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () =>
  //     import("@/views/space/meetings/adminConfirmAttendance.vue"),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/meeting-minutes/:meeting_minutes_id",
  //   name: "meeting_minutes",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () =>
  //     import(
  //       "@/views/space/meetings/current-meeting-items/meeting_minutes.vue"
  //     ),
  // },
  // {
  //   path: "/entities/:entity_id/space/:space_id/meetings/meeting-minutes/:meeting_minutes_id/edit",
  //   name: "editMeetingMinutes",
  //   meta: {
  //     layout: meetingLayout,
  //   },
  //   component: () =>
  //     import(
  //       "@/views/space/meetings/current-meeting-items/editMeetingMinutes.vue"
  //     ),
  // },
]
export default meetingRoute
