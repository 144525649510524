// main.ts
import { createApp } from "vue"
import App from "@/App.vue"
import router from "@/router"
import VueTelInput from "vue-tel-input"
import "vue-tel-input/vue-tel-input.css"

import "@/assets/style/main.scss"
import VOtpInput from "vue3-otp-input"
import VCalendar from "v-calendar"
import "v-calendar/style.css"

import { createPinia } from 'pinia'

const pinia = createPinia()

import useAssets from "@/mixins/useAssets"
import "@/js/shephard.js"
import "./registerServiceWorker"
import "aos/dist/aos.css"
import vue3GoogleLogin from "vue3-google-login"
import PhosphorIcons from "@phosphor-icons/vue"
import i18n from "@/plugins/i18n"

// Import moment configuration
// import "./moment-config.ts";

// Import VueDatetimeJs after configuring moment-jalaali
// import VueDatetimeJs from "vue-datetime-js";

import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"

const app = createApp(App)
  .mixin(useAssets)
  .use(router)
  .use(pinia)
  .use(i18n)
  .use(VueTelInput)
  .use(VOtpInput)
  // .use("date-picker")
  .use(VCalendar)
  .use(PhosphorIcons)
  .use(VueDatePicker)

  .use(vue3GoogleLogin, {
    clientId: import.meta.env.VITE_BASE_URL
  })
// .use(VueDatetimeJs); // Use VueDatetimeJs after configuring moment-jalaali

app.config.performance = true

app.mount("#app")
