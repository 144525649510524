import SettingRoutes from "./settingRoutes"
import MemberRoutes from "./memberRoutes"
import SubscriptionRoutes from "./subscriptionRoutes"
import CalenderRoutes from "./calenderRoutes"
import boardLayout from "@/layout/boardLayout.vue"
const EntityRoute = [
  {
    path: "/",
    name: "entities",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/entities.vue")
  },
  // {
  //   path: "/entities/:entity_id",
  //   name: "entity",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },
  //   component: () => import("@/views/entity/entity_details.vue"),
  // },
  {
    path: "/entities/:entity_id/spaces",
    name: "entity",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/entity/spaces.vue")
  }
]

export default EntityRoute.concat(
  SettingRoutes,
  MemberRoutes,
  SubscriptionRoutes,
  CalenderRoutes
)
