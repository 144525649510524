const useAssets = {
  methods: {
    getImageFromAssets(imgFileDir) {
      // console.log('imgFileDir', imgFileDir);
      return new URL(`../assets/${imgFileDir}`, import.meta.url).href
    }
  }
}

export default useAssets
