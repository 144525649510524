import authLayout from "@/layout/authLayout.vue"

const authRoutes = [
  {
    path: "/login",
    name: "login",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/login.vue")
  },
  {
    path: "/auth/forget-password",
    name: "forgetPassword",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/forget-password.vue")
  },
  {
    path: "/auth/reset-password",
    name: "resetPassword",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/reset-password.vue")
  },
  {
    path: "/register",
    name: "register",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/register/register.vue")
  },

  {
    path: "/register/password",
    name: "registerPassword",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/register/password.vue")
  },
  {
    path: "/register/verification-code-register",
    name: "verificationCodeRegister",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () =>
      import("@/views/auth/register/verification-code-register.vue")
  },
  {
    path: "/register/onboarding",
    name: "onboarding",
    meta: {
      layout: authLayout,
      authGard: false
    },
    component: () => import("@/views/auth/register/onboarding.vue")
  }
]
export default authRoutes
