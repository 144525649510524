import boardLayout from "@/layout/boardLayout.vue"
// import committeeElectionLayout from "@/layout/committeeElectionLayout.vue"

const electionsRoutes = [
  // ---- start list

  {
    path: "/entities/:entity_id/space/:space_id/election",
    name: "election",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/election/election.vue")
  },

  // Election Bookmark
  {
    path: "/entities/:entity_id/space/:space_id/election/bookmark",
    name: "electionBookmark",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/bookmark/electionBookmark.vue")
  },

  // ---- end list

  // ---- start committee Election Details

  {
    path: "/entities/:entity_id/space/:space_id/election/committee/:election_id",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    name: "committeeElectionLayout",
    component: () => import("@/layout/committeeElectionLayout.vue"),

    children: [
      {
        path: "details",
        name: "details",

        component: () =>
          import("@/views/space/election/details/electionDetails.vue")
      },
      {
        path: "nomination",
        name: "nomination",

        component: () =>
          import("@/views/space/election/details/electionNominations.vue")
      },

      {
        path: "voting",
        name: "voting",

        component: () =>
          import("@/views/space/election/details/electionVoting.vue")
      },
      {
        path: "ended",
        name: "ended",

        component: () =>
          import("@/views/space/election/details/electionEnded.vue")
      }
    ]
  },

  // review application

  {
    path: "/entities/:entity_id/space/:space_id/election/committee/:election_id/applications/:application_id",
    name: "applicationRevision",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () =>
      import("@/views/space/election/details/applicationRevision.vue")
  },

  // --- end committee Election Details

  // show dt and applay

  {
    path: "/entities/:entity_id/space/:space_id/election/apply/:election_id",
    name: "shareholderApplying",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () =>
      import("@/views/space/election/shareholder/shareholderApplying.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/election/active/details/shareholder/:election_id",
    name: "activeElectionDetailsForShareholder",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () =>
      import("@/views/space/election/shareholder/electionShareholderView.vue")
  },

  // add
  {
    path: "/entities/:entity_id/space/:space_id/election/add",
    name: "addNewElection",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/election/add.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/election/edit-periods/:election_id",
    name: "editElectionPeriod",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/election/editPeriods.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/election/edit-basic/:election_id",
    name: "editElectionBasic",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/election/editBasic.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/election/edit-voting/:election_id",
    name: "editElectionVoting",
    meta: {
      layout: boardLayout,
      authGard: true
    },
    component: () => import("@/views/space/election/editVoting.vue")
  }
  // {
  //   path: "/entities/:entity_id/space/:space_id/election/ended-election/:election_id",
  //   name: "endedElection",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true
  //   },

  //   component: () => import("@/views/space/election/endedElection.vue")
  // }
]
export default electionsRoutes
