import boardLayout from "@/layout/boardLayout.vue"

const groupsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/groups",
    name: "groups",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/groups.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/groups/add",
    name: "addGroup",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/addGroup.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/groups/:group_id",
    name: "groupList",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/groupList.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/groups/:group_id/edit",
    name: "editGroup",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/editGroup.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/groups/:group_id/add-member",
    name: "addMembersToGroup",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/addMemberToGroup.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/groups/:group_id/add-member-xls",
    name: "addMembersAsXLS",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/groups/addMembersAsXLS.vue")
  }
]

export default groupsRoutes
