import boardLayout from "@/layout/boardLayout.vue"

const shareholdersRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/shareholders",
    name: "shareholdersList",
    component: () => import("@/views/space/shareholders/List.vue"),
    meta: {
      layout: boardLayout,
      authGard: true
    }
  },

  {
    path: "/entities/:entity_id/space/:space_id/shareholders/add",
    component: () => import("@/views/space/shareholders/Add.vue"),
    name: "addShareholder",
    meta: {
      layout: boardLayout,
      authGard: true
    }
  }
]
export default shareholdersRoutes
