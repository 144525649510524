<template>
  <div
    :style="computedStyle"
    class="space_hero"
    :class="backGround ? 'bg-img' : coverColor"
  >
    <!-- first header -->
    <div class="first-header gap-4 flex items-center justify-between">
      <!-- side one -->
      <div class="gap-4 breadcump flex items-center">
        <!-- to user -->
        <ag-back v-if="entity_id" class="cursor-pointer" @click="toUser">
          <template v-slot:icon>
            <div class="justify-center flex items-center ps-2 pe-2">
              <ph-arrow-bend-right-up weight="bold" color="#FFF" />
            </div>
          </template>
          <template v-slot:content>
            <div class="header-link-info flex items-center h-[48px]">
              <ag-avatar
                :src="user_info.user_image"
                :containerClasses="` w-[32px] h-[32px] rounded-full`"
                imgClasses="rounded-full"
              />
            </div>
          </template>
        </ag-back>
        <!-- to entity -->
        <ag-back class="cursor-pointer" @click="toEntity()">
          <template v-slot:icon>
            <div class="justify-center flex items-center ps-2 pe-2">
              <ph-arrow-bend-right-up weight="bold" color="#FFF" />
            </div>
          </template>
          <template v-slot:content>
            <div class="header-link-info flex items-center h-[48px]">
              <div class="items-center flex gap-15 smooth_rounded index-12">
                <ag-avatar
                  v-if="entity_info.company_logo"
                  :src="entity_info.company_logo"
                  :containerClasses="` w-[32px] h-[32px]`"
                  :imgClasses="` w-[32px] h-[32px]`"
                />

                <div v-else class="text-white user-name hidden md:block">
                  {{ entity_info.company_name }}
                </div>
              </div>
            </div>
          </template>
        </ag-back>
        <!-- to space if not home -->
        <ag-back
          v-if="currentRoute !== 'space'"
          class="cursor-pointer"
          @click="toSpace()"
        >
          <template v-slot:icon>
            <div class="justify-center flex items-center ps-2 pe-2">
              <ph-arrow-bend-right-up weight="bold" color="#FFF" />
            </div>
          </template>
          <template v-slot:content>
            <div class="header-link-info flex items-center h-[48px]">
              <!-- logo at hero  -->
              <div
                v-if="space_info.logo"
                class="items-center flex gap-15 smooth_rounded index-12"
              >
                <ag-avatar
                  :src="space_info.logo"
                  :containerClasses="` w-[32px] h-[32px]`"
                  :imgClasses="` w-[32px] h-[32px]`"
                />
              </div>

              <div
                v-if="!space_info.logo"
                class="text-white user-name hidden md:block"
              >
                {{ space_info.space_name }}
              </div>
            </div>
          </template>
        </ag-back>
      </div>
      <!--    if home -->
      <ag-back
        v-if="currentRoute == 'space'"
        class="cursor-pointer"
        @click="toSpace()"
      >
        <template v-slot:content>
          <div class="header-link-info flex items-center h-[48px]">
            <!-- logo at hero  -->
            <div
              v-if="space_info.logo"
              class="items-center flex gap-15 smooth_rounded index-12"
            >
              <ag-avatar
                :src="space_info.logo"
                :containerClasses="` w-[32px] h-[32px]`"
                :imgClasses="` w-[32px] h-[32px]`"
              />
            </div>

            <div v-else class="text-white user-name ms-3">
              {{ space_info.space_name }}
            </div>
            <div class="justify-center flex items-center ms-2">
              <ph-bookmark-simple weight="bold" color="#FFF" />
            </div>
          </div>
        </template>
      </ag-back>
      <!-- back to applications -->
      <!-- to space if not home -->
      <div id="back-to-app"></div>
      <div class="flex-1 flex justify-end">
        <hero-content-buttons
          class="content-buttons flex items-center"
        ></hero-content-buttons>
      </div>
    </div>
    <!-- seconded header -->
    <!-- Second header -->
    <div
      :class="[
        'seconded-header-holder',
        isHeaderFixed ? 'fixed-hero bg-img' : ''
      ]"
      :style="isHeaderFixed ? computedStyle : ''"
    >
      <div
        id="sub-hero"
        class="gap-4 seconded-header flex justify-between items-center"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()

const spaceStore = useSpaceStore()
const entityStore = useEntityStore()
const space_id = computed(() => route.params.space_id)
const entity_id = computed(() => route.params.entity_id)
const authStore = useAuthStore()
const entity_title = ref("")
const title = ref("")
const avatar = ref("")
const entity_avatar = ref("")
const isHeaderFixed = ref(false)
const backGround = ref("")
const coverColor = ref("")

// const space_info = computed(() => spaceStore.spacesInfo.value)
// const entity_info = computed(() => entityStore.entityInfo.value)
// const user_info = computed(() => authStore.user.value)
import { storeToRefs } from "pinia"

const { entityInfo } = storeToRefs(entityStore)
const { spacesInfo } = storeToRefs(spaceStore)
const user_info = computed(() => authStore.user)
const entity_info = computed(() => entityInfo.value)
const space_info = computed(() => spacesInfo.value)
const computedStyle = computed(() => {
  const defaultColor = "#f00"
  const background = backGround.value
  const cover = coverColor.value || defaultColor

  const backgroundStyle = background
    ? `url(${background})`
    : `linear-gradient(91deg, rgba(18, 18, 18, 0) -28.37%, rgba(18, 18, 18, 0.5) 93.56%), ${cover}`

  return {
    [background ? "backgroundImage" : "background"]: backgroundStyle
  }
})

const currentRoute = computed(() => route.name)

const handleScroll = () => {
  isHeaderFixed.value = window.scrollY >= 82
}

const get_space_entity = async () => {
  await spaceStore.updateSpaceInfo(space_id.value)
  await entityStore.updateEntityInfo(entity_id.value)
}

const updateEntityInfo = () => {
  if (entity_info.value && Object.keys(entity_info.value).length) {
    entity_title.value = entity_info.value.company_name || ""
    entity_avatar.value = entity_info.value.company_logo || ""
  } else {
    resetDefaults()
  }
}

const updateSpaceInfo = () => {
  if (space_info.value && Object.keys(space_info.value).length) {
    backGround.value = space_info.value.cover_image || ""
    title.value = space_info.value.space_name || ""
    avatar.value = space_info.value.logo || ""
  } else {
    resetDefaults()
  }
}

const resetDefaults = () => {
  entity_title.value = ""
  entity_avatar.value = ""
  backGround.value = ""
  title.value = ""
  avatar.value = ""
}

const toEntity = () => {
  router.push({ name: "entity" })
}

const toSpace = () => {
  router.push({ name: "space" })
}

const toUser = () => {
  router.push({ name: "entities" })
}

watch(
  () => route,
  () => {
    if (entity_id.value && space_id.value) {
      get_space_entity()
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => entity_info.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      updateEntityInfo()
    }
  }
)

watch(
  () => space_info.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      updateSpaceInfo()
    }
  }
)

onMounted(() => {
  window.addEventListener("scroll", handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})
</script>
