import boardLayout from "@/layout/boardLayout.vue"

const subSpaceRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/sub-spaces",
    name: "subSpaces",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/sub-space/list.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/create/add_space",
    name: "addNewSpace",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/new-space/addNewSpace.vue")
  },

  // {
  //   path: "/entities/:entity_id/space/:space_id/my-spaces",
  //   name: "mySpaces",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },

  //   component: () => import("@/views/space/spaces/mySpaces.vue"),
  // },

  {
    path: "/entities/:entity_id/space/:space_id/view_sub_space",
    name: "viewSubSpace",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/sub-space/View.vue")
  }
  // {
  //   path: "/entities/:entity_id/space/:space_id/add_sub_space",
  //   name: "addSubSpace",
  //   meta: {
  //     layout: boardLayout,
  //     authGard: true,
  //   },

  //   component: () =>
  //     import("@/views/space/sub-space/new-sub-space/addSubSpace.vue"),
  // },
]

export default subSpaceRoutes
