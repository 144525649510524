import DelegationRoutes from "./delegationRoutes"
import ItemsRoute from "./itemsRoute"
import MeetingRoutes from "./meetingRoutes"
import RecommendationsRoutes from "./recommendationsRoutes"

export default DelegationRoutes.concat(
  ItemsRoute,
  MeetingRoutes,
  RecommendationsRoutes
)
