<template>
  <div class="hero-content-buttons hidden md:block">
    <div class="gap-1 hero-buttons flex items-center">
      <router-link class="icon-hero smooth_rounded" to="/ask">
        <ph-question :size="24" color="#FFF" />
      </router-link>
      <!-- save -->
      <router-link class="icon-hero smooth_rounded" to="/notifications">
        <ph-bell-simple :size="24" color="#fff" />
      </router-link>
      <div @click="logOut()" class="icon-hero smooth_rounded">
        <ph-sign-out class="rotate-180" :size="24" color="#fff" />
      </div>

      <div class="icon-hero smooth_rounded">
        <router-link class="d-block" to="/">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.9699 4.72435L20.974 2.39209C20.8817 2.40403 20.8298 2.4102 20.7371 2.42173L18.4062 5.41917C18.4178 5.51157 18.4241 5.56344 18.4357 5.65585L21.4315 7.98811C21.5239 7.97658 21.5757 7.96999 21.6685 7.95867L23.9993 4.96124C23.9878 4.86883 23.9814 4.81696 23.9699 4.72414V4.72435Z"
              fill="white"
            />
            <path
              d="M13.1192 0.622192L6.3138 0.00146484L4.67086 1.69756C5.20856 3.20327 5.33774 5.3254 4.64247 6.94575C2.70332 11.466 -1.28503 17.2843 0.409544 22.7881H0.40399L0.841929 23.9985H7.44187V23.4304L7.19441 22.7463C4.12986 14.349 4.45714 12.5455 6.30989 7.41376H6.7394L9.97303 16.3519L12.7393 23.9985H20.3955V20.7351L13.1192 0.622192Z"
              fill="white"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
  <div class="text-right z-30 block sm:block md:hidden">
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton
          class="inline-flex w-full justify-center rounded-[8px] font-medium text-white focus:outline-none"
        >
          <ph-dots-three-vertical :size="32" weight="bold" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-[8px] bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <div class="px-1 py-1 flex gap-1">
            <MenuItem v-slot="{ active }">
              <router-link class="icon-hero smooth_rounded" to="/ask">
                <ph-question :size="24" color="#000" />
              </router-link>
            </MenuItem>

            <MenuItem v-slot="{ active }">
              <router-link class="icon-hero smooth_rounded" to="/notifications">
                <ph-bell-simple :size="24" color="#000" />
              </router-link>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <div @click="logOut()" class="icon-hero smooth_rounded">
                <ph-sign-out class="rotate-180" :size="24" color="#000" />
              </div>
            </MenuItem>

            <div class="icon-hero smooth_rounded">
              <router-link class="d-block" to="/">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.9699 4.72435L20.974 2.39209C20.8817 2.40403 20.8298 2.4102 20.7371 2.42173L18.4062 5.41917C18.4178 5.51157 18.4241 5.56344 18.4357 5.65585L21.4315 7.98811C21.5239 7.97658 21.5757 7.96999 21.6685 7.95867L23.9993 4.96124C23.9878 4.86883 23.9814 4.81696 23.9699 4.72414V4.72435Z"
                    fill="white"
                  />
                  <path
                    d="M13.1192 0.622192L6.3138 0.00146484L4.67086 1.69756C5.20856 3.20327 5.33774 5.3254 4.64247 6.94575C2.70332 11.466 -1.28503 17.2843 0.409544 22.7881H0.40399L0.841929 23.9985H7.44187V23.4304L7.19441 22.7463C4.12986 14.349 4.45714 12.5455 6.30989 7.41376H6.7394L9.97303 16.3519L12.7393 23.9985H20.3955V20.7351L13.1192 0.622192Z"
                    fill="#12b76a"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue"
import { useRouter } from "vue-router"
const authStore = useAuthStore()
const { logout } = useAuthStore()

const router = useRouter()
function logOut() {
  const { saveUser } = useAuthStore()
  saveUser(null)
  localStorage.removeItem("user")
  localStorage.removeItem("jwt")
  localStorage.removeItem("user_id")
  logout()

  router.push({ name: "login" })
}
</script>
