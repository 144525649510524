import boardLayout from "@/layout/boardLayout.vue"

const delegationRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/delegations",
    name: "delegationList",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/delegation/list.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/delegations/add",
    name: "addDelegation",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/delegation/add.vue")
  }
]
export default delegationRoutes
