import boardLayout from "@/layout/boardLayout.vue"

const memberRoutes = [
  {
    path: "/entities/:entity_id/members",
    name: "entityMembers",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/members/members")
  },
  {
    path: "/entities/:entity_id/members/:id",
    name: "entityMemberProfile",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/members/memberInfo")
  },
  {
    path: "/entities/:entity_id/members/add",
    name: "addEntityMember",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/members/add")
  }
]

export default memberRoutes
