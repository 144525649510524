<template>
  <div class="fixed bottom-5 right-5 flex flex-col gap-2 z-30 rounded-lg">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="p-4 rounded shadow-lg bg-white flex gap-4 items-center"
    >
      <ag-base-icon
        :name="notification.icon.name"
        :class="containerClasses(notification.icon.bg)"
        customClasses="w-5 h-5 "
        weight="bold"
      />
      <div class="toaster-text">
        <p class="text-[#313F52]">{{ notification.message }}</p>
        <p class="text-[12px] text-[#667085]">
          {{ notification.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const notificationStore = useNotificationStore()
const notifications = computed(() => notificationStore.notifications)

const containerClasses = (bgColor) => {
  return `w-12 h-12 ${bgColor} text-[#FFF]`
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
