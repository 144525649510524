import axios from "axios"
import router from "../router"
import { storeToRefs } from "pinia"
import moment from "moment"
export const ai = axios.create({
  baseURL:
    import.meta.env.VITE_BASE_URL && import.meta.env.VITE_BASE_URL !== "false"
      ? import.meta.env.VITE_BASE_URL + "/api/"
      : undefined
})

let isRefreshing = true
let failedQueue = []
const prepareHeaders = (config, { token, memberId, lang }) => {
  config.headers["Authorization"] = token
  config.headers["member-id"] = memberId
  config.headers["Accept-Language"] = lang
}
ai.interceptors.request.use(
  async (config) => {
    const { refreshToken } = useAuthStore()
    const { getCurrentMemberId, user } = storeToRefs(useAuthStore())
    const userFromLocalStorage = JSON.parse(localStorage.getItem("user"))
    console.log("user", user.value)
    console.log("user", userFromLocalStorage)

    if (userFromLocalStorage?.token) {
      let token = userFromLocalStorage.token
      prepareHeaders(config, {
        token: token,
        memberId: getCurrentMemberId.value || userFromLocalStorage.member_id,
        lang: "ar"
      })

      let tokenExpiredAfterOneMinute =
        userFromLocalStorage?.exp &&
        moment(userFromLocalStorage?.exp * 1000).diff(moment(), "minutes") <= 2

      if (tokenExpiredAfterOneMinute && isRefreshing) {
        isRefreshing = false
        await refreshToken(userFromLocalStorage.refresh_token)
        isRefreshing = true
        let newUser = JSON.parse(localStorage.getItem("user"))
        prepareHeaders(config, {
          token: newUser.token,
          memberId: getCurrentMemberId.value || userFromLocalStorage.member_id,
          lang: "ar"
        })
        return config
      }
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

ai.interceptors.response.use(
  (response) => {
    return Promise.resolve({ data: response.data.message })
  },
  async (error) => {
    const { refreshToken } = useAuthStore()
    const originalRequest = error.config
    if (error.response?.status === 403) {
    }

    if (
      error.response?.status == 401 &&
      router.currentRoute.value.name !== "login"
    ) {
      // alert("you not have permission")
      router.push("/login")
      localStorage.clear()
    }
    if (!localStorage.getItem("user")) {
      // router.push("/login");
    }

    if (
      (!localStorage.getItem("entity_info") && localStorage.getItem("user")) ||
      (!localStorage.getItem("space_info") && localStorage.getItem("user"))
    ) {
      // router.push("/undefined");
      // router.push({ name: "notFound" });
    }

    if (error.response?.status == 500) {
      // router.push({ name: "notFound" });
    }
    return Promise.reject(error.response)
  }
)
