import boardLayout from "@/layout/boardLayout.vue"

const itemsRoute = [
  {
    path: "/entities/:entity_id/space/:space_id/items",
    name: "itemsList",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/items/List.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/items/add",
    name: "addItems",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/items/add.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/items/:agenda_id",
    name: "viewItems",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/items/view.vue")
  }
]

export default itemsRoute
