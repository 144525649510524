import { defineStore } from "pinia"
import { ai } from "@/plugins/axios"

export const useSpaceStore = defineStore({
  id: "Space",
  state: () => ({
    spacesInfo: []
  }),
  actions: {
    async updateSpaceInfo(spaceId) {
      try {
        const { data } = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: spaceId } }
        )
        this.spacesInfo = data
      } catch (err) {
        console.log(err)
      }
    }
  }
})
