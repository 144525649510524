import boardLayout from "@/layout/boardLayout.vue"
const spaceRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id",
    component: () => import("@/views/space/workspace/mainSpaceHome.vue"),
    children: [
      {
        path: "",
        name: "space",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/workspace/space.vue")
      },
      {
        path: "settings",
        name: "SpaceSetting",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/space-settings/SpaceSetting.vue")
      },
      {
        path: "members",
        name: "members",
        component: () => import("@/views/space/members/members.vue"),
        meta: {
          layout: boardLayout,
          authGard: true
        }
      },
      {
        path: "members/add",
        name: "addSpaceMember",
        component: () => import("@/views/space/members/add.vue"),
        meta: {
          layout: boardLayout,
          authGard: true
        }
      },
      {
        path: "members/:id",
        name: "memberProfile",
        component: () => import("@/views/space/members/profile.vue"),
        meta: {
          layout: boardLayout,
          authGard: true
        }
      },
      {
        path: "logs",
        name: "spaceLogs",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/space-panal/spaceLogs.vue")
      },

      {
        path: "",
        name: "rotations",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/rotations/list.vue")
      },
      {
        path: "add",
        name: "addRotation",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/rotations/addRotation.vue")
      },
      {
        path: "edit/:id",
        name: "editRotation",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/rotations/updateRotation")
      },

      {
        path: "info",
        name: "viewSpace",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/info/view")
      },
      {
        path: "info/edit",
        name: "editSpace",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/info/edit")
      },

      {
        path: "",
        name: "roles",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/roles/list.vue")
      },
      {
        path: "add_role_permissions",
        name: "addRolePermissions",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/roles/add.vue")
      },
      {
        path: ":role",
        name: "updatedRole",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/roles/update.vue")
      },

      {
        path: "recycle",
        name: "space-recycle",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/space-panal/recycle.vue")
      },
      {
        path: "customize",
        name: "customize",
        meta: {
          layout: boardLayout,
          authGard: true
        },
        component: () => import("@/views/space/space-settings/customize.vue")
      }
    ]
  }
]

export default spaceRoutes
