<template>
  <!-- <slot name="button"></slot> -->
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      as="div"
      class="relative z-40"
      @close="$emit('update:isOpen', false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-[rgb(10,10,10)]/40" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center"
          :class="position === 'end' ? 'justify-end' : 'justify-center'"
        >
          <TransitionChild
            as="template"
            :enter="transitionClasses.enter"
            :enter-from="transitionClasses.enterFrom"
            :enter-to="transitionClasses.enterTo"
            :leave="transitionClasses.leave"
            :leave-from="transitionClasses.leaveFrom"
            :leave-to="transitionClasses.leaveTo"
          >
            <DialogPanel
              class="max-w-[50%] transform shadow-xl transition-all rounded-lg overflow-hidden bg-[#F5F5F4]"
              :class="planeClasses"
            >
              <!-- <DialogTitle
                class="text-lg flex font-medium leading-6 text-gray-900"
              > -->
              <slot name="title"></slot>
              <slot name="content"></slot>
              <slot name="controller"></slot>

              <!-- </DialogTitle> -->
              <!-- <div class="mt-2"> -->
              <!-- </div> -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed } from "vue"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  planeClasses: {
    type: String,
    default: ""
  },
  position: {
    type: String,
    default: "center"
  }
})

const transitionClasses = computed(() => {
  if (props.position === "center") {
    return {
      enter: "transform transition duration-300 ease-out",
      enterFrom: "translate-x-0 opacity-0",
      enterTo: "translate-x-0 opacity-100",
      leave: "transform transition duration-200 ease-in",
      leaveFrom: "translate-x-0 opacity-100",
      leaveTo: "translate-x-0 opacity-0"
    }
  } else if (props.position === "end") {
    return {
      enter: "transform transition duration-300 ease-out",
      enterFrom: "-translate-x-full opacity-0",
      enterTo: "translate-x-0 opacity-100",
      leave: "transform transition duration-200 ease-in",
      leaveFrom: "translate-x-0 opacity-100",
      leaveTo: "-translate-x-full opacity-0"
    }
  }
})
</script>
