<template>
  <div class="header-avatar-holder" :class="containerClasses">
    <img
      :key="src"
      loading="lazy"
      class="header-avatar"
      :class="imgClasses"
      :src="imgSrc"
      :alt="src"
      @error="handleImageError"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"

interface Props {
  containerClasses?: string
  imgClasses?: string
  src?: string
  fromLocal?: boolean
}

// Define props
const props = withDefaults(defineProps<Props>(), {
  containerClasses: "",
  imgClasses: "",
  src: "",
  fromLocal: false
})

// Data
const fallbackSrc =
  "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"
const isError = ref(false)
const imgSrc = ref("")

watch(()=> props.src,(newVal)=>{
  console.log(newVal)
  if(newVal){

    imgSrc.value = newVal?.includes("http") || props.fromLocal ? newVal: `https://dev.agenda.42.guru/${newVal}`
  }
  else{
    imgSrc.value = fallbackSrc
  }

} , {immediate:true})

// Method to handle image error
const handleImageError = (e) => {
  console.log(e)
  isError.value = true
}
</script>

<style scoped>
/* Add your styles here */
</style>
