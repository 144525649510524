<template>
  <footer
    class="flex items-center justify-between layout-padding layout-container mb-6 mt-6"
  >
    <div class="items-center flex justify-between gap-10">
      <div class="footer-text">
        {{ $t("allRightSavedToAgende") }} - {{ $t("oneOfTheProducts") }}
      </div>
      <span>
        <svg
          width="21"
          height="10"
          viewBox="0 0 21 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4998 4.99976L5.5 0L0.500233 4.99977L5.5 9.99953L10.4998 4.99976Z"
            fill="#98A2B3"
          />
          <path
            d="M15.4993 5C15.4994 4.34343 15.3701 3.69326 15.1189 3.08664C14.8677 2.48001 14.4995 1.92881 14.0353 1.46451C13.571 1.00021 13.0199 0.631899 12.4133 0.380617C11.8067 0.129334 11.1566 -7.07258e-09 10.5 0V10C11.1566 10 11.8067 9.87067 12.4133 9.61939C13.0199 9.3681 13.571 8.99979 14.0353 8.53549C14.4995 8.07119 14.8677 7.51999 15.1189 6.91336C15.3701 6.30674 15.4994 5.65658 15.4993 5Z"
            fill="#98A2B3"
          />
          <path
            d="M20.5 10V0C19.1739 0 17.9021 0.526784 16.9645 1.46447C16.0268 2.40215 15.5 3.67392 15.5 5C15.5 6.32609 16.0268 7.59785 16.9645 8.53554C17.9021 9.47322 19.1739 10 20.5 10Z"
            fill="#98A2B3"
          />
        </svg>
      </span>
    </div>
    <div class="footer-links items-center flex">
      <router-link to="/terms-and-conditions">{{
        $t("termsAndConditions")
      }}</router-link>
      <div class="point"></div>
      <router-link to="/privacy-policy">{{ $t("privacyPolicy") }}</router-link>
      <div class="point"></div>
      <router-link to="/contact-us">{{ $t("contactUS") }}</router-link>
      <div class="point"></div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>
