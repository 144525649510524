import boardLayout from "@/layout/boardLayout.vue"

const subscriptionRoutes = [
  {
    path: "/:entity_id/subscriptions-bills",
    name: "subscriptionBills",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () =>
      import("@/views/entity/subscriptions/subscriptionBills.vue")
  },
  {
    path: "/entities/:entity_id/payment-data",
    name: "paymentData",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/subscriptions/paymentData.vue")
  },
  {
    path: "/entities/:entity_id/payment",
    name: "payment",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/entity/subscriptions/payment.vue")
  }
]
export default subscriptionRoutes
