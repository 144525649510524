<template>
  <div>
    <Teleport v-if="isMounted" to="#sub-hero">
      <ag-space-apps />

      <ag-header-taps>
        <template v-slot:taps>
          <button
            :class="['tap-item', panel == 0 ? 'active' : '']"
            @click="openModal(0)"
          >
            <ph-bookmark-simple color="#fff" size="20" weight="fill" />
          </button>
          <button
            :class="['tap-item', panel == 1 ? 'active' : '']"
            @click="openModal(1)"
          >
            {{ $t("summary") }}
          </button>
        </template>
      </ag-header-taps>

      <div class="page-actions shrink-0">
        <ag-dialog :title="$t('addEntity')">
          <template v-slot:button>
            <ag-btn class="blur">
              <template v-slot:text>
                <div class="font-weight-bold">
                  {{ $t("addSubSpace") }}
                </div>
              </template>
              <template v-slot:icon>
                <ph-plus weight="bold" size="20" />
              </template>
            </ag-btn>
          </template>

          <template v-slot:content>
            <add-entity />
          </template>
        </ag-dialog>
      </div>
    </Teleport>

    <div>
      <h1>notifications</h1>
    </div>
  </div>
</template>

<script>
import { ai } from "@/plugins/axios"

export default {
  data() {
    return {
      space_id: this.$route.params.space_id,
      entity_id: this.$route.params.entity_id,
      isMounted: false
    }
  },

  methods: {},
  mounted() {
    this.isMounted = true
  },
  created() {
    document.title = this.$t("notifications")
  }
}
</script>
