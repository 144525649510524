import boardLayout from "@/layout/boardLayout.vue"

const newsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/news",
    name: "spaceNews",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/news/List.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/news/add",
    name: "addSpaceNews",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/news/Add.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/news/edit/:news_article_id",
    name: "spaceEditNews",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/news/Edit.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/news/view/:news_article_id",
    name: "spaceViewNews",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/news/View.vue")
  }
]
export default newsRoutes
