import boardLayout from "@/layout/boardLayout.vue"
const informationRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/member/edit/:member_id",
    name: "editMemberProfile",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/member/editMemberProfile.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/member/view/:member_id",
    name: "memberProfile",
    meta: {
      layout: boardLayout,
      authGard: true
    },

    component: () => import("@/views/space/member/memberProfile.vue")
  }
]

export default informationRoutes
