import boardLayout from "@/layout/boardLayout.vue"
const informationRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/annotations",
    name: "annotations",
    meta: {
      layout: boardLayout,
      authGard: false
    },

    component: () => import("@/views/annotations/annotations.vue")
  }
]

export default informationRoutes
