<template>
  <div>
    <div
      :style="computedStyle"
      class="main-hero"
      :class="backGround ? 'bg-img' : coverColor"
    >
      <!-- First header -->
      <div class="first-header flex items-start justify-between">
        <div class="flex-1 user-back justify-start flex items-center">
          <!-- To user -->
          <ag-back v-if="entity_id" class="cursor-pointer" @click="toUser">
            <template v-slot:icon>
              <div class="justify-center flex items-center ps-2 pe-2">
                <ph-arrow-bend-right-up weight="bold" color="#FFF" />
              </div>
            </template>
            <template v-slot:content>
              <div class="header-link-info flex items-center">
                <ag-avatar
                  src="https://dev.agenda.42.guru//files/Personal%20image.png"
                  :containerClasses="` w-[32px] h-[32px]`"
                  imgClasses="rounded-full"
                />
              </div>
            </template>
          </ag-back>
        </div>
        <div class="justify-center middle-avatar flex-1 flex items-center">
          <div class="relative">
            <ag-base-icon
              v-if="entity_id"
              name="PhBookmarkSimple"
              type="rounded"
              containerClasses="w-5 h-5 bg-[#fff] text-[#313F52] rounded-full flex items-center justify-center !p-0 absolute top-[-6px] start-[-30px] cursor-pointer"
              customClasses="w-3 h-3"
              :weight="1 > 2 ? 'fill' : 'bold'"
            />
            <ag-avatar
              v-if="avatar"
              :src="avatar"
              :containerClasses="`  w-[96px] h-[96px] ${avatarType === 'circle' ? 'rounded-full' : 'smooth_rounded'}`"
              :imgClasses="`w-[100%] h-[100%] ${avatarType === 'circle' ? 'rounded-full' : 'smooth_rounded'}`"
            />
          </div>
          <div v-if="!avatar" class="text-white text-[2rem]">
            {{ title }}
          </div>
        </div>
        <div class="flex-1 flex justify-end">
          <hero-content-buttons
            class="content-buttons flex items-center"
          ></hero-content-buttons>
        </div>
      </div>
      <!-- Second header -->
      <div
        :class="[
          'seconded-header-holder',
          isHeaderFixed ? 'fixed-hero bg-img' : ''
        ]"
        :style="isHeaderFixed ? computedStyle : ''"
      >
        <div
          id="sub-hero"
          class="seconded-header flex wrap-anywhere justify-between items-center gap-4"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()

const entityStore = useEntityStore()
const authStore = useAuthStore()

const entity_info = computed(() => entityStore.entityInfo)
const user_info = computed(() => authStore.user)
const entity_id = computed(() => route.params.entity_id)

const isHeaderFixed = ref(false)
const backGround = ref("")
const coverColor = ref("")
const avatar = ref("")
const title = ref("")
const avatarType = ref("")

const computedStyle = computed(() => {
  const defaultColor = "#006EB7"
  const background = backGround.value
  const cover = coverColor.value || defaultColor

  const backgroundStyle = background
    ? `url(${background})`
    : `linear-gradient(91deg, rgba(18, 18, 18, 0) -28.37%, rgba(18, 18, 18, 0.5) 93.56%), ${cover}`

  return {
    [background ? "backgroundImage" : "background"]: backgroundStyle
  }
})

const handleScroll = () => {
  isHeaderFixed.value = window.scrollY >= 160
}

const get_entity = async () => {
  try {
    await entityStore.updateEntityInfo(entity_id.value)
  } catch (error) {
    console.error("Failed to update entity information:", error)
  }
}

const updateEntityInfo = () => {
  if (entity_info.value && Object.keys(entity_info.value).length) {
    backGround.value = entity_info.value.company_cover || ""
    title.value = entity_info.value.company_name || ""
    avatar.value = entity_info.value.company_logo || ""
    avatarType.value = entity_info.value.type === "Personal" ? "circle" : ""
  } else {
    resetDefaults()
  }
}

const updateUserInfo = () => {
  if (user_info.value && Object.keys(user_info.value).length) {
    backGround.value = user_info.value.user_cover || ""
    title.value = user_info.value.full_name || ""
    avatar.value = user_info.value.user_image || ""
    avatarType.value = "circle"
  } else {
    resetDefaults()
  }
}

const resetDefaults = () => {
  backGround.value = "#f2f4f7"
  title.value = ""
  avatar.value = ""
  avatarType.value = ""
}

const toUser = () => {
  router.push({ name: "entities" })
}

watch(
  () => route,
  () => {
    if (entity_id.value) {
      get_entity()
    } else {
      updateUserInfo()
    }
  },
  { immediate: true, deep: true }
)

watch(
  () => entity_info.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      updateEntityInfo()
    }
  }
)

onMounted(() => {
  window.addEventListener("scroll", handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll)
})
</script>
