import boardLayout from "@/layout/boardLayout.vue"

const taskRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/tasks",
    name: "tasks",
    component: () => import("@/views/space/tasks/list.vue"),
    meta: {
      layout: boardLayout,
      authGard: true
    }
  },
  {
    path: "/entities/:entity_id/space/:space_id/tasks/add",
    name: "addTask",
    component: () => import("@/views/space/tasks/add.vue"),
    meta: {
      layout: boardLayout,
      authGard: true
    }
  },
  {
    path: "/entities/:entity_id/space/:space_id/tasks/edit/:task_id",

    name: "editTask",
    component: () => import("@/views/space/tasks/edit.vue"),
    meta: {
      layout: boardLayout,
      authGard: true
    }
  },
  {
    path: "/entities/:entity_id/space/:space_id/tasks/:task_id",
    name: "taskDetails",
    component: () => import("@/views/space/tasks/view.vue"),
    meta: {
      layout: boardLayout,
      authGard: true
    }
  }
]

export default taskRoutes
