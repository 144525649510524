<template>
  <button
    class="ag-btn"
    :class="loading ? 'loading' : ''"
    :title="title"
    v-bind="$attrs"
    :style="{
      minWidth: minWidth,
      height: height,
      width: width,
      justifyContent: justifyContent,
      borderRadius: rounded,
      border: border,
      padding: padding
    }"
    :disabled="disabled || loading"
  >
    <div
      v-if="$slots.icon"
      class="flex align-center flex-shrink justify-center"
    >
      <svg
        v-if="loading"
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <slot v-else class="action-btn" name="icon"></slot>
    </div>

    <slot class="action-btn" name="text"></slot>

    <router-link
      class="btn-link"
      v-if="link"
      :to="to"
      :title="title"
    ></router-link>
  </button>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean
    },
    minWidth: {
      type: String,
      default: "max-content"
    },
    width: {
      type: String,
      default: "auto"
    },
    height: {
      type: String,
      default: "48px"
    },
    justifyContent: {
      type: String,
      default: "center"
    },
    to: {
      default: ""
    },
    link: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "button to"
    },
    rounded: {
      type: String,
      default: "9999px"
    },
    border: {
      type: String,
      default: "1px solid rgba(255, 255, 255, 0.2)"
    },
    padding: {
      type: String,
      default: "10px 24px"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
}
</script>

<style lang="scss">
@import "./../../../assets/style/base/variables.scss";

.ag-btn {
  padding: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  padding: 10px 24px;
  position: relative;
  flex-shrink: 0;

  // disabled

  &:disabled {
    opacity: 0.7;
    color: #ffffffbb;
  }
  &.loading {
    & > * {
      opacity: 0;
    }
    a > * {
      // display: none;
    }

    &::after {
      content: "";
      display: block;
      width: 1.2em;
      height: 1.2em;
      position: absolute;
      left: calc(50% - 0.75em);
      top: calc(50% - 0.75em);
      border: 0.15em solid transparent;
      border-right-color: white;
      border-radius: 50%;
      animation: button-anim 0.7s linear infinite;
      // opacity: 0;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }

  .font-weight-bold {
    flex-shrink: 0;
  }

  &.bg-gray-border {
    background-color: $light-gray;
    border: 1px solid $light;
    color: $bullet-gray;
  }

  .btn-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
}

@keyframes button-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: $tablet) {
  .ag-btn {
    width: 48px;
    svg {
    }
    .font-weight-bold {
    }

    &.bg-gray-border {
    }

    &.blur {
      padding: 0px;
    }
    .btn-link {
    }
  }
}
</style>
